function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{attrs:{"name":_vm.modalName,"height":"auto"},on:{"before-open":_vm.onBeforeOpen}},[_c('div',{staticClass:"modal-body"},[_c('close-button',{on:{"click":function($event){return _vm.$modal.hide(_vm.modalName)}}}),(_vm.isLoading)?_c('loader',{staticClass:"modal-body__loader"}):[_c('svg-icon',{staticClass:"modal-body__icon",attrs:{"name":"settings"}}),_c('heading',{staticClass:"modal-body__heading",attrs:{"level":"3"}},[_vm._v(" Нужно пройти двухфакторную авторизацию ")]),_c('span',{staticClass:"modal-body__text"},[_vm._v(" Мы сделали запрос в вашем магазине на сайте AliExpress. Код авторизации должен прийти вам на почту или телефон. ")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('the-form',{staticClass:"modal-body__form",on:{"submit":function($event){return handleSubmit(_vm.onSubmit)}}},[_c('x-form-item',{attrs:{"rules":"required","name":"Код авторизации"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var rest = objectWithoutProperties( ref, ["errors"] );
var validationData = rest;
return [_c('x-input',_vm._b({attrs:{"placeholder":"Введите код авторизации"},on:{"input":function($event){_vm.loginErrorMessage = ''}},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}},'x-input',validationData,false)),(_vm.loginErrorMessage && !errors.length)?_c('span',{staticClass:"x-form-item__errors"},[_vm._v(" "+_vm._s(_vm.loginErrorMessage)+" ")]):_vm._e()]}}],null,true)}),_c('x-btn',{staticClass:"modal-body__btn",attrs:{"type":"submit","block":"","loading":_vm.isCodeSending}},[_vm._v(" Отправить ")])],1)]}}])})]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }