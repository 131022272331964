<template>
  <modal
    :name="modalName"
    height="auto"
    @before-open="onBeforeOpen"
  >
    <div class="modal-body">
      <close-button @click="$modal.hide(modalName)" />
      <loader
        v-if="isLoading"
        class="modal-body__loader"
      />

      <template v-else>
        <svg-icon
          name="settings"
          class="modal-body__icon"
        />

        <heading
          level="3"
          class="modal-body__heading"
        >
          Нужно пройти двухфакторную авторизацию
        </heading>

        <span class="modal-body__text">
          Мы сделали запрос в вашем магазине на сайте AliExpress. Код авторизации должен прийти вам на почту или телефон.
        </span>

        <validation-observer v-slot="{ handleSubmit }">
          <the-form
            class="modal-body__form"
            @submit="handleSubmit(onSubmit)"
          >
            <x-form-item
              v-slot="{ errors, ...validationData }"
              rules="required"
              name="Код авторизации"
            >
              <x-input
                v-model="code"
                v-bind="validationData"
                placeholder="Введите код авторизации"
                @input="loginErrorMessage = ''"
              />
              <span
                v-if="loginErrorMessage && !errors.length"
                class="x-form-item__errors"
              >
                {{ loginErrorMessage }}
              </span>
            </x-form-item>

            <x-btn
              type="submit"
              block
              :loading="isCodeSending"
              class="modal-body__btn"
            >
              Отправить
            </x-btn>
          </the-form>
        </validation-observer>
      </template>
    </div>
  </modal>
</template>

<script>
import Loader from '@/components/Common/Loader.vue'
import Heading from '@/components/Common/Heading.vue'
import TheForm from '@/components/Interface/Form.vue'
import CloseButton from '@/components/Interface/CloseButton.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('marketplaces')

export default {
  components: {
    Loader,
    Heading,
    TheForm,
    CloseButton
  },

  data () {
    return {
      modalName: '2fa-modal',
      id: '',
      code: '',
      requestId: '',
      loginErrorMessage: '',
      isLoading: false,
      isCodeSending: false
    }
  },

  methods: {
    ...mapActions([
      'send2FACode',
      'request2FACode'
    ]),

    async onBeforeOpen (e) {
      this.id = e.params?.id

      try {
        this.isLoading = true

        this.requestId = await this.request2FACode(this.id)
        if (!this.requestId) {
          this.$modal.hide(this.modalName)
        }
      } finally {
        this.isLoading = false
      }
    },

    async onSubmit () {
      try {
        this.isCodeSending = true

        const { isSuccess, errorMessage } = await this.send2FACode({
          id: this.id,
          request_id: this.requestId,
          code: this.code
        })

        if (isSuccess) {
          this.$toast.success('Магазин успешно авторизован')
          this.$modal.hide(this.modalName)
          this.$emit('success')
        } else {
          this.loginErrorMessage = errorMessage
        }
      } finally {
        this.isCodeSending = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-body
    text-align center
    color #3c3c47
    padding 26px 58px

    &__icon
      width 64px
      height 64px
      color #5b40ff

    &__heading
      font-size 20px

    &__text
      font-size 14px

    &__form
      width 230px
      align-self center
      margin 20px auto 0

    &__btn
      margin-top 12px

    &__loader
      padding 90px 0
</style>
