<template>
  <modal
    :name="modalName"
    :max-width="540"
    :min-width="280"
    width="90%"
    height="auto"
    :adaptive="true"
    :click-to-close="false"
    @before-open="onBeforeOpen"
    @closed="onClosed"
  >
    <div class="modal-container">
      <validation-observer
        v-if="!isFormSended"
        v-slot="{ handleSubmit }"
      >
        <the-form
          class="form_content_connect-marketplace"
          @submit="handleSubmit(onSubmit)"
        >
          <fieldset class="form-fields-group">
            <legend class="form-fields-group__title">
              Осталось совсем чуть-чуть
            </legend>

            <ul class="form-fields-list">
              <li class="form-fields-list__item form-fields-list__item_content_desc">
                Для автоматизации работы с акциями нам требуются логин и пароль от вашего кабинета AliExpress.
                Мы храним данные в зашифрованном виде, доступ к ним есть только у алгоритма.
              </li>

              <li class="form-fields-list__item">
                <x-form-item
                  v-slot="validationData"
                  rules="required"
                  name="Логин от AliExpress"
                  label="Логин от AliExpress"
                >
                  <x-input
                    v-model="form.login"
                    v-bind="validationData"
                    placeholder="Логин"
                  />
                </x-form-item>
              </li>

              <li class="form-fields-list__item">
                <x-form-item
                  v-slot="validationData"
                  label="Пароль от AliExpress"
                  name="Пароль от AliExpress"
                  rules="required"
                >
                  <x-input
                    v-model="form.password"
                    v-bind="validationData"
                    placeholder="Пароль"
                    :type="showPass ? 'text' : 'password'"
                    :append-icon="showPass ? 'eye-off' : 'eye-on'"
                    @click:append="showPass = !showPass"
                  />
                </x-form-item>
              </li>

              <li class="form-fields-list__item form-fields-list__item_content_submit">
                <ul class="modal-container__actions">
                  <li class="modal-container__action">
                    <x-btn
                      type="button"
                      outline
                      block
                      @click="$modal.hide(modalName)"
                    >
                      Отмена
                    </x-btn>
                  </li>

                  <li class="modal-container__action">
                    <x-btn
                      type="submit"
                      block
                      :loading="isLoading"
                    >
                      Предоставить доступ
                    </x-btn>
                  </li>
                </ul>
              </li>
            </ul>
          </fieldset>
        </the-form>
      </validation-observer>

      <success-connection
        v-if="isFormSended"
        heading="Доступ получен!"
        text="Мы ищем для Вас свеженькие акции."
        @click="onFinishClick"
      />
    </div>
  </modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import TheForm from '@/components/Interface/Form.vue'
import SuccessConnection from '@/components/Marketplaces/ConnectForms/SuccessConnection.vue'
import { authStatuses } from '@/constants/shops'

const { mapActions, mapGetters } = createNamespacedHelpers('marketplaces')

export default {
  components: {
    TheForm,
    SuccessConnection
  },

  data () {
    return {
      modalName: 'ali-auth-modal',
      id: null,
      isFormSended: false,
      isLoading: false,
      showPass: false,
      loginErrorMessage: '',
      form: {
        login: '',
        password: ''
      }
    }
  },

  computed: {
    ...mapGetters(['lastAliShop'])
  },

  methods: {
    ...mapActions([
      'getShopList',
      'loginShop'
    ]),

    async onBeforeOpen (e) {
      if (!e.params) {
        await this.getShopList({ marketplace_code: 'ali' })
        return
      }

      const { id } = e.params

      if (id) this.id = id
    },

    onClosed () {
      this.form.login = ''
      this.form.password = ''
      this.isFormSended = false
    },

    async onSubmit () {
      try {
        this.isLoading = true

        if (this.lastAliShop || this.id) {
          const id = this.id || this.lastAliShop.id

          const { isSuccess, shop, message } = await this.loginShop({
            id,
            ...this.form
          })

          await this.$user.getProfile()

          if (shop.authorization_status === authStatuses.requires2FA) {
            this.$modal.show('2fa-modal', { id })
          }

          if (isSuccess) {
            this.isFormSended = true
          } else {
            this.loginErrorMessage = message
          }
        }
      } finally {
        this.isLoading = false
      }
    },

    onFinishClick () {
      this.$modal.hide(this.modalName)
      this.$emit('success')
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-container
    max-width 390px
    margin 0 auto
    padding 20px 15px 60px
    box-sizing border-box

    @media (min-width 768px)
      padding 20px 0 60px

    &__actions
      display flex
      justify-content space-between
      margin 0
      padding 0
      list-style none

    &__action
      flex-basis 48%
</style>
